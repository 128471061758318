import { useMsal, useAccount } from "@azure/msal-react";
import { useCallback } from "react";
import { loginRequestParams, useSetAccessToken } from "../../modules/azure";
import './style.scss';

type Props = {

}

const AzureLogin: React.FC<Props> = () => {

    const setAccessToken = useSetAccessToken();

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const login = useCallback(() => {
        if (account) {
            instance.acquireTokenSilent({
                ...loginRequestParams,
                account: account
            }).then((response) => {
                setAccessToken(response.accessToken);
            })
        } else {
            instance.loginPopup(loginRequestParams).then((response) => {
                setAccessToken(response.accessToken);
            })
        }
    }, [account, instance, setAccessToken]);

    return <div className="azure-login">
        <div className="top-gfx"></div>
        <div className="content-wrapper">
            <h1 className="heading-1">Witamy!</h1>

            <div className="welcome-text">
                <p>Dostęp do gry mają tylko konta w domenie warszawa.edu.pl</p>
            </div>
            <nav>
                <button className="button" onClick={login}>Zaloguj się do gry</button>
            </nav>
        </div>
    </div>
}

export default AzureLogin;
