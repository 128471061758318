import { useMsal } from "@azure/msal-react"
import { useCallback } from "react"
import { CONFIG } from "../../config"
import { useGameRunModuleDispatch, GAME_RUN_ACTION } from "../../modules/game-run"
import { useUserModuleDispatch, USER_ACTION } from "../../modules/user"
import useLogout from "../../modules/user/hooks/use-logout"

type Props = {

}

const LogoutButton: React.FC<Props> = () => {
    return <>
        {(CONFIG.HOSTING_TYPE === 'azure') && <AzureLogoutButton />}
        {(CONFIG.HOSTING_TYPE === 'classic') && <ClassicLogoutButton />}
    </>
}


const AzureLogoutButton: React.FC<Props> = () => {

    const dispatch = useUserModuleDispatch();
    const runDispath = useGameRunModuleDispatch()

    const {instance} = useMsal();
    const logout = useCallback(() => {
        dispatch({
            type: USER_ACTION.LOGOUT,
            payload: {}
        });

        runDispath({
            type: GAME_RUN_ACTION.RESET,
            payload: {}
        });

        instance.logout();


    }, [instance]);

    return <button className="button btn-inverted btn-small" onClick={logout}>Wyloguj</button>
}


const ClassicLogoutButton: React.FC<Props> = () => {

    const logout = useLogout()

    return <button className="button btn-inverted btn-small" onClick={logout}>Wyloguj</button>
}

export default LogoutButton;
