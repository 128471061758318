import { useCallback } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { USER_ROLE } from "../../modules/user";
import useLogin from "../../modules/user/hooks/use-login";

type Props = {

}

const Container = styled.div`
    label {
        display: block;
        margin: 1em;
    }
`;

const DemoLoginForm: React.FC<Props> = () => {

    const {handleSubmit, register} = useForm();
    const login = useLogin();

    const onSubmit = useCallback((data: any) => {
        login(data.userId, data.userRole);
    }, [login]);

    return <Container>
        <h2>Uwaga! - to jest tylko zastępczy formularz logowania dla środowiska niepołaczonego z prawdziwym katalogiem użytkowników.</h2>
        <form onSubmit={handleSubmit(onSubmit)}>

            <div>
                <label>
                    Kim jesteś? <br />
                    <select name="userRole" ref={register}>
                        <option value={USER_ROLE.PLAYER}>Uczeń</option>
                        <option value={USER_ROLE.TEACHER}>Nauczyciel</option>
                    </select>
                </label>
                <label>
                        Podaj swoje ID<br />
                        <input name="userId" ref={register({
                            required: true
                        })}></input>
                </label>
            </div>

            <button type="submit">Wejdź</button>

        </form>
    </Container>
}

export default DemoLoginForm;
