import Axios from 'axios';
import { useCallback } from 'react';
import createAxiosProxider from './network-provider';

type GetConfigResponse = {
    serverTime: number
}

function getConfig(): Promise<GetConfigResponse> {

    return Promise.resolve({
        serverTime: Date.now()
    });
}

function getGame(): Promise<any> {
    return Axios.get('/data.json')
        .then((response) => {
            return {
                steps: response.data.steps,
                startStep: response.data.startStep,
                summary: response.data.summary
            }
        })
}

function useGetGameRunRequest() {
    const axios = useAxios();

    return useCallback((id: number) => {
        const request = axios.get(`/run/info/${id}`)
            .then((response) => {
                const data = response.data;

                const result = {
                    choices: [
                        { key: 'pon1530', value: data["pon1530"] },
                        { key: 'wt1000', value: data["wt1000"] },
                        { key: 'wt1659', value: data["wt1659"] },
                        { key: 'sr1400', value: data["sr1400"] },
                        { key: 'pt1600', value: data["pt1600"] },
                        { key: 'sr1800', value: data["sr1800"] },
                        { key: 'pt1630', value: data["pt1630"] },
                        { key: 'cz1530', value: data["cz1530"] },
                        { key: 'cz2000', value: data["cz2000"] },
                        { key: 'pt0800', value: data["pt0800"] },
                        { key: 'sb1100', value: data["sb1100"] },
                        { key: 'pn0855', value: data["pn0855"] }
                    ],
                    rules: [
                        (data.rule1 || null),
                        (data.rule2 || null),
                        (data.rule3 || null)
                    ]
                }

                return result
            });

        return request;
    }, [axios]);
}

function useNewGameRunRequest() {
    const axios = useAxios();

    return useCallback(() => {
        return axios.get('/run/start')
            .then((response) => {
                return {
                    user: {
                        id: response.data.user.objectID,
                        firstRun: response.data.user.firstRun,
                        lastRun: response.data.user.lastRun,
                        activeRun: response.data.user.activeRun,
                        activeStep: response.data.user.activeStep
                    },
                    runID: response.data.runID
                }
            })
    }, [axios]);
}

function useUpdateActiveStepRequest() {
    const axios = useAxios();

    return useCallback((stepID: string) => {
        return axios.post('/user/active-step', {
            activeStep: stepID
        })
    }, [axios]);
}

function useLoginRequest() {
    const axios = useAxios();

    return useCallback((params: { id: string, role: string }) => {
        return axios.post('/login', {
            ...params
        });
    }, [axios]);
}

function useLogoutRequest() {
    const axios = useAxios();

    return useCallback(() => {
        return axios.post('/logout');
    }, [axios]);
}

function useRegisterChoiceRequest() {
    const axios = useAxios();

    return useCallback((params: { runID: number, key: string, value: number }) => {
        return axios.post('/choice', params);
    }, [axios]);
}

function useSaveRulesRequest() {
    const axios = useAxios();

    return useCallback((params: { runID: number, rule1: string, rule2: string, rule3: string }) => {
        return axios.post('/rules', params);
    }, [axios]);

}

function useFinishRunRequest() {
    const axios = useAxios();

    return useCallback((params: { runID: number }) => {
        return axios.post('/run/complete', params)
            .then(response => {
                return {
                    ...response.data
                }
            })
    }, [axios])
}

export type ClassInfo = {
    id: string,
    name: string
}

function useGetClassesListRequest(): () => Promise<ClassInfo[]> {
    const axios = useAxios();

    return useCallback(() => {
        return axios.get('/classes')
            .then(response => response.data);
    }, [axios]);
}

function useGetClassRequest(id: string) {
    const axios = useAxios();

    return useCallback(() => {
        return axios.get(`/class/${id}`)
    }, [axios, id]);
}

function useGetClassListRequest(id: string) {
    const axios = useAxios();

    return useCallback(() => {
        return axios.get(`/class-members/${id}`)
            .then((response) => {
                return [
                    ...response.data.map((row: any) => {
                        return {
                            id: row.id,
                            name: row.name,
                            firstRun: row.first_run,
                            lastRun: row.last_run,
                        }
                    })
                ]
            });
    }, [axios, id]);
}

function useRulesListRequest(id: string) {
    const axios = useAxios();

    return useCallback(() => {
        return axios.get(`/rules-list/${id}`)
            .then((response) => {
                return [
                    ...response.data.map((row: any) => {
                        return {
                            user: row.name,
                            rule: row.rule
                        }
                    })
                ]
            });
    }, [axios, id]);

}

function useClassRaportRequest(id: string) {
    const axios = useAxios();

    return useCallback(() => {

        return axios.get(`/class-raport/${id}`)
            .then(response => response.data);
    }, [axios, id]);

}


const Network = {
    getConfig,
    getGame
}

export default Network;


const [
    NetworkProvider,
    useAxios,
] = createAxiosProxider();

function useGetUserRequest() {
    const axios = useAxios();

    return useCallback(() => {
        return axios.get(`/user`)
            .then((response) => {
                return {
                    id: response.data.objectID,
                    name: response.data.name,
                    firstRun: response.data.firstRun,
                    lastRun: response.data.lastRun,
                    activeRun: response.data.activeRun,
                    activeStep: response.data.activeStep,
                    role: response.data.role
                }
            });
    }, [axios])
}

export {
    NetworkProvider,
    useAxios,
    useGetUserRequest,
    useNewGameRunRequest,
    useGetGameRunRequest,
    useUpdateActiveStepRequest,
    useLoginRequest,
    useLogoutRequest,
    useRegisterChoiceRequest,
    useSaveRulesRequest,
    useFinishRunRequest,
    useGetClassListRequest,
    useRulesListRequest,
    useClassRaportRequest,
    useGetClassesListRequest,
    useGetClassRequest
}
